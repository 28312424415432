import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/demo/components/SuspenseLoader';
import Reports from './dashboard/presentation/Reports';
import AppBaseLayout from './layouts/AppLayout';
import { LoginPage } from './login/presentation/LoginView';
import DashboardPage from './pages/DashboadPage';
import OrdersDashboard from './quotes/presentation/OrdersDashboard';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// App Pages
const OrdersPage = Loader(
    lazy(() => import('./quotes/presentation/OrdersView'))
    //lazy(() => import('./pages/QuotesPage'))
);
const QuotesDetail = Loader(
    lazy(() => import('./quotes/presentation/OrderDetailView'))
);
const QuotesList = Loader(
    lazy(() => import('./quotes/presentation/QuotesListView'))
);
const ProductsPage = Loader(
    lazy(() => import('./products/presentation/ProductsView'))
);
const ProductsDashboard = Loader(
    lazy(() => import('./products/presentation/ProductDashboardView'))
);
const Warehouse = Loader(
    lazy(() => import('./warehouse/presentation/Warehouse'))
);
const WarehouseOrdersList = Loader(
    lazy(() => import('./warehouse/presentation/WarehouseOrdersList'))
);

const Tracking = Loader(
    lazy(() => import('./tracking/presentation/TrackingView'))
);

const TrackingList = Loader(
    lazy(() => import('./tracking/presentation/TrackingList'))
);

const Dashboard = Loader(
    lazy(() => import('./dashboard/presentation/DashboardPage'))
);
// Status

const Status404 = Loader(
    lazy(() => import('src/demo/content/pages/Status/Status404'))
);
const Status500 = Loader(
    lazy(() => import('src/demo/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
    lazy(() => import('src/demo/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
    lazy(() => import('src/demo/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
    {
        path: '',
        element: <BaseLayout />,
        children: [
            {
                path: '/',
                // element: <Overview />
                element: <Navigate to="/app" replace />
            },
            {
                path: '/sign-in',
                element: <LoginPage />
            },
            {
                path: 'overview',
                element: <Navigate to="/" replace />
            },
            {
                path: 'status',
                children: [
                    {
                        path: '',
                        element: <Navigate to="404" replace />
                    },
                    {
                        path: '404',
                        element: <Status404 />
                    },
                    {
                        path: '500',
                        element: <Status500 />
                    },
                    {
                        path: 'maintenance',
                        element: <StatusMaintenance />
                    },
                    {
                        path: 'coming-soon',
                        element: <StatusComingSoon />
                    }
                ]
            },
            {
                path: '*',
                element: <Status404 />
            }
        ]
    },
    {
        path: '/app',
        element: <AppBaseLayout />,
        children: [
            {
                path: '',
                element: <Navigate to={'home'} />
            },
            {
                path: 'home',
                element: <DashboardPage />
            },
            {
                path: 'dashboard',
                element: <Dashboard />,
                children: [
                    {
                        path: '',
                        element: <Navigate to={'reports'} />
                    },
                    {
                        path: 'reports',
                        element: <Reports />
                    }
                ]
            },
            {
                path: 'orders',
                element: <OrdersPage />,
                children: [
                    {
                        path: '',
                        element: <Navigate to="list" />
                    },
                    {
                        path: 'home',
                        element: <OrdersDashboard />
                    },
                    {
                        path: 'list',
                        element: <QuotesList />
                    },
                    {
                        path: 'list/detail/:id',
                        element: <QuotesDetail />
                    }
                ]
            },

            {
                path: 'products',
                element: <ProductsPage />,
                children: [
                    {
                        path: '',
                        element: <Navigate to="dashboard" />
                    },
                    {
                        path: 'dashboard',
                        element: <ProductsDashboard />
                    }
                ]
            },
            {
                path: 'warehouse',
                element: <Warehouse />,
                children: [
                    {
                        path: '',
                        element: <Navigate to="list" />
                    },
                    {
                        path: 'list',
                        element: <WarehouseOrdersList />
                    }
                ]
            },
            {
                path: 'tracking',
                element: <Tracking />,
                children: [
                    {
                        path: '',
                        element: <Navigate to="list" />
                    },
                    {
                        path: 'list',
                        element: <TrackingList />
                    }
                ]
            }
        ]
    }
];

export default routes;
