import {
    GraphicEqSharp,
    Inventory2TwoTone,
    ListAltTwoTone,
    LocalShipping,
    Warehouse
} from '@mui/icons-material';
import { createElement } from 'react';

export const sidebarListItems = [
    /* 
    {
        title: 'Productos',
        path: '/app/products',
        icon:  React.createElement(Inventory2TwoToneIcon),
    },
    {
        title: 'Órdenes',
        path: '/app/orders',
        icon:  React.createElement(ListAltTwoToneIcon)
    },*/ {
        title: 'Dashboard',
        path: '/app/dashboard',
        icon: createElement(GraphicEqSharp),
        role: ['1', '2']
    },
    {
        title: 'Órdenes',
        path: '/app/orders',
        icon: createElement(ListAltTwoTone),
        role: ['1', '2']
    },
    {
        title: 'Productos',
        path: '/app/products',
        icon: createElement(Inventory2TwoTone),
        role: ['1']
    },
    {
        title: 'Bodega',
        path: '/app/warehouse',
        icon: createElement(Warehouse),
        role: ['1', '2', '4', '8', '9']
    },
    {
        title: 'Rastreo',
        path: '/app/tracking',
        icon: createElement(LocalShipping),
        role: ['1', '2']
    }
];
