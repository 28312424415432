import {
    Box,
    Button,
    Grid,
    Link,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthContext';
import AuthService from 'src/services/AuthService';
import LoginRepository from '../data/repository/LoginRepository';
import LoginUseCase from '../domain/LoginUseCase';

export function LoginPage() {
    const theme = useTheme();

    const { setTokenHandler } = useAuth();
    const navigate = useNavigate();

    const service = new AuthService();
    const repository = new LoginRepository(service);
    const loginUsecase = new LoginUseCase(repository);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        if (isLoading) return;
        setIsLoading(true);
        setErrorMessage('');
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email').valueOf() as string;
        const password = data.get('password').valueOf() as string;

        await loginUsecase
            .execute(email, password)
            .then((response) => {
                setTokenHandler(response.data.token);
                navigate('/app', { replace: true });
            })
            .catch((err) => {
                let errorMsg =
                    'Ha sucedido un error, favor de intentar más tarde';
                if (err.code == 'ERR_BAD_REQUEST') {
                    errorMsg = 'Credenciales Inválidas';
                }
                setErrorMessage(errorMsg);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '$F6F6F6',
                '@media (max-width: 800px)': {
                    '#login-container': {
                        flexDirection: 'column'
                    }
                }
            }}
        >
            <Box position={'inherit'}>
                <div
                    style={{
                        height: '100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            background: '#4461F2',
                            boxShadow: '317px 317px 317px ',
                            borderRadius: 9999,
                            filter: 'blur(317px)'
                        }}
                    />
                    <div
                        style={{
                            width: 296.8,
                            height: 339.24,
                            left: 0,
                            top: 0,
                            position: 'absolute',
                            opacity: 0.45,
                            background: '#DDA82A',
                            boxShadow: '317px 317px 317px ',
                            borderRadius: 9999,
                            filter: 'blur(317px)'
                        }}
                    />
                </div>
            </Box>
            <Box
                display="flex"
                justifyContent="between center"
                alignItems={'center'}
                id="login-container"
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                }}
            >
                <Box
                    display={'flex'}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        padding: '20px',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <h1>
                        <span
                            id="page-title"
                            style={{
                                color: 'black',
                                fontSize: 96,
                                fontWeight: theme.typography.h1.fontWeight
                            }}
                        >
                            Forza
                        </span>
                        <br />
                        <span
                            style={{
                                color: 'black',
                                fontSize: 40,
                                fontWeight: 400
                            }}
                        >
                            An e-commerce oms.
                        </span>
                        <br />
                        <span
                            style={{
                                color: 'black',
                                fontSize: 24,
                                fontWeight: 200
                            }}
                        >
                            by Alltrade Mx.
                        </span>
                    </h1>
                </Box>
                <Box
                    display={'flex'}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        padding: '20px',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 2 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email "
                            name="email"
                            autoComplete="email"
                            inputProps={{ style: { outlineColor: 'blue' } }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {errorMessage ? (
                            <Typography color="error">
                                {errorMessage}
                            </Typography>
                        ) : (
                            ''
                        )}
                        <Grid container>
                            <Grid
                                item
                                xs
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    mt: 2,
                                    textDecorationColor: '#4F555A'
                                }}
                            >
                                <Link href="#" variant="body2">
                                    ¿Olvidaste tu contraseña?
                                </Link>
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: '#F85437' }}
                            disabled={isLoading}
                        >
                            Iniciar Sesión
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
