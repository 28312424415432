import api from '../../data/network/NetworkModule';
import { GuidesReportResponse } from './models/response/GuidesReportResponse';
export class DashboardService {
    private path = '/reports';

    async getGuidesReports(channel: string): Promise<GuidesReportResponse> {
        return api
            .get(`${this.path}/guides`, {
                params: {
                    channel: channel
                }
            })
            .then((response) => {
                const data = response.data;
                return data;
            })
            .catch((err) => {
                console.log(err);
            });
    }
}
