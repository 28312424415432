import { GetGuidesReportsMapper } from '../presentation/mapppers/GetGuidesReportsMapper';
import { DashboardService } from './DashboardService';

export class DashboardRepository {
    private _service = new DashboardService();

    async getGuidesReports(channel: string) {
        const result = await this._service.getGuidesReports(channel);

        const data = GetGuidesReportsMapper(result);

        console.log(data);

        return data;
    }
}
